<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="920"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card v-if="loading" min-width="320">
      <v-card-text>
        Please stand by
        <v-progress-linear
          indeterminate
          color="grey"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row v-show="!isCreate">
              <v-col cols="12">
                <v-text-field
                  v-model="item.id"
                  label="ID"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="item.name"
                  label="グループ名"
                  :rules="rules.name"
                  counter="128"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="item.idp_identifier"
                  label="LINEログイン 識別名"
                  hint="アラートマップURLの一部として表示される部分です。任意の文字(英数字)を入力してください。"
                  persistent-hint
                  :rules="rules.idp_identifier"
                  counter="128"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="!isCreate">
              <v-col>
                <v-text-field
                  :value="
                    `${item.map_login_url}/auth/federated-sign-in?provider=${item.idp_identifier}`
                  "
                  label="LINEログイン アラートマップURL"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <header>LINE設定</header>
                <v-radio-group v-model="item.role">
                  <v-radio
                    v-for="role in roles"
                    :key="role.name"
                    :label="role.label"
                    :value="role.name"
                    :rules="rules.required"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <div v-if="isLineCustom">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.line_official_account_name"
                    label="LINE公式アカウント名"
                    hint="作成されたLINE公式アカウント名を入力してください。"
                    persistent-hint
                    :rules="rules.line_official_account_name"
                    counter="128"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.line_login_channel_id"
                    label="LINEログイン チャネルID"
                    hint="LINE Developersにて作成されたLINEログインチャネルIDを入力してください。"
                    persistent-hint
                    :rules="rules.required"
                    counter="128"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.line_login_channel_secret"
                    label="LINEログイン チャネルシークレット"
                    hint="LINE Developersにて作成されたLINEログインチャネルシークレットを入力してください。"
                    persistent-hint
                    :rules="rules.required"
                    counter="128"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.line_messaging_api_channel_access_token"
                    label="LINE Messaging API チャネルアクセストークン"
                    hint="LINE Developersにて作成されたLINE Messaging API のチャネルアクセストークンを入力してください。"
                    persistent-hint
                    counter="256"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.line_messaging_api_channel_secret"
                    label="LINE Messaging API チャネルシークレット"
                    hint="LINE Developersにて作成されたLINE Messaging API のチャネルシークレットを入力してください。"
                    persistent-hint
                    counter="128"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col>
                <header>センサ表示設定</header>
                <v-radio-group v-model="item.display_type">
                  <v-radio
                    v-for="type in displayTypes"
                    :key="type.name"
                    :label="type.label"
                    :value="type.value"
                    :disabled="type.disabled"
                    :rules="rules.required"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="item.description"
                  label="備考"
                  counter="2048"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-switch v-model="item.enabled" label="無効 / 有効"></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="close">
            キャンセル
          </v-btn>
          <v-btn depressed dark :color="mainColor" @click="save">保存</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getGroup, postGroup, putGroup } from '@/api'
import { MAIN_COLOR, DISPLAY_TYPES, DISPLAY_TYPE_MAP } from '@/constants'

export default {
  name: 'GroupEditor',
  props: {
    dialog: Boolean,
    id: String
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      loading: false,
      valid: true,
      rules: {
        name: [
          v => !!v || 'グループ名は必須です',
          v => {
            if (v === this.oldGroupName) {
              return true
            }
            return v in this.groupNameMap
              ? `${v} は存在するグループ名のため使用できません。`
              : true
          }
        ],
        idp_identifier: [
          v => !!v || 'LINEログイン 識別名は必須です',
          v =>
            RegExp(/[^a-zA-Z0-9-_]/g).test(v)
              ? '半角英数字ハイフン(-)アンダーバー(_)のみ入力してください。'
              : true
        ],
        required: [v => !!v || '必須項目です']
      },
      defaultItem: {
        id: '',
        name: '',
        role: 'general',
        line_official_account_name: '',
        idp_identifier: '',
        line_messaging_api_channel_access_token: '',
        line_messaging_api_channel_secret: '',
        line_login_channel_id: 'dummy',
        line_login_channel_secret: 'dummy',
        map_login_url: '',
        display_type: DISPLAY_TYPE_MAP,
        description: '',
        enabled: false
      },
      editItem: {
        id: '',
        name: '',
        role: 'general',
        line_official_account_name: '',
        idp_identifier: '',
        line_messaging_api_channel_access_token: '',
        line_messaging_api_channel_secret: '',
        line_login_channel_id: '',
        line_login_channel_secret: '',
        map_login_url: '',
        display_type: DISPLAY_TYPE_MAP,
        description: '',
        enabled: false
      },
      defaultRoles: [
        { name: 'general', label: 'デフォルト' },
        { name: 'custom', label: 'カスタム' }
      ],
      displayTypes: DISPLAY_TYPES,
      oldGroupName: ''
    }
  },
  computed: {
    ...mapGetters(['isAdminGroup', 'groupNameMap']),
    isCreate() {
      return this.id === ''
    },
    title() {
      return this.isCreate ? '新規登録' : '編集'
    },
    item() {
      return this.editItem
    },
    isAdmin() {
      return !this.isCreate && this.editItem.role == 'admin'
    },
    isLineCustom() {
      return this.item.role !== 'general'
    },
    roles() {
      if (this.isAdmin) {
        return [{ name: 'admin', label: '管理者' }]
      }
      if (!this.isAdminGroup) {
        return [{ name: 'custom', label: 'カスタム' }]
      }
      return this.defaultRoles
    }
  },
  watch: {
    async dialog(openend) {
      if (openend && !this.isCreate) {
        await this.setItems()
      }
    }
  },
  methods: {
    ...mapActions(['addGroup', 'updateGroup', 'setFullscreenLoading']),
    close() {
      this.$refs.form.resetValidation()
      this.editItem = Object.assign(this.editItem, this.defaultItem)
      this.oldGroupName = ''
      this.$emit('close')
    },
    async groupById(id) {
      this.loading = true
      let group
      try {
        const res = await getGroup(id)
        group = res.group
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.loading = false
      return group
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.setFullscreenLoading(true)
      try {
        if (this.isCreate) {
          const res = await postGroup(this.item)
          this.addGroup(res.group)
        } else {
          const res = await putGroup(this.item, 'base')
          this.updateGroup(res.group)
        }
      } catch (e) {
        console.error(e)
        if (e.response.status === 400) {
          alert(e.response.data.message)
        } else {
          alert('サーバエラーが発生しました')
        }
      }
      this.close()
      this.setFullscreenLoading(false)
    },
    async setItems() {
      const group = await this.groupById(this.id)
      this.oldGroupName = group.name
      if (group.role !== 'general') {
        this.editItem = Object.assign(this.editItem, group)
        return
      }
      this.editItem = Object.assign(this.editItem, {
        id: group.id,
        name: group.name,
        role: this.isAdminGroup ? group.role : 'custom',
        line_official_account_name: '',
        idp_identifier: group.idp_identifier,
        line_messaging_api_channel_access_token: '',
        line_messaging_api_channel_secret: '',
        line_login_channel_id: '',
        line_login_channel_secret: '',
        map_login_url: group.map_login_url,
        display_type: group.display_type || DISPLAY_TYPE_MAP,
        description: group.description,
        enabled: group.enabled
      })
    }
  }
}
</script>
