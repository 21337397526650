<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col>
          <h1>グループ</h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col v-if="isAdminGroup" class="text-right">
          <v-btn :color="mainColor" dark @click.stop="doCreate">
            新規登録
          </v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn icon @click="doLoad(false)">
            <v-progress-circular
              v-show="loading"
              :indeterminate="loading"
            ></v-progress-circular>
            <v-icon v-show="!loading">mdi-reload</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="groups"
          :loading="loading"
          :search="search"
        >
          <template v-slot:[`item.enabled`]="{ item }">
            <v-chip
              :color="item.enabled ? 'success' : 'grey'"
              label
              small
              outlined
              >{{ item.enabled ? '有効' : '無効' }}
            </v-chip>
          </template>
          <template v-slot:[`item.setting`]="{ item }">
            <v-icon small @click="doSettingEdit(item.id)">
              mdi-cog
            </v-icon>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon small class="mr-2" @click.stop="doEdit(item.id)"
              >mdi-pencil</v-icon
            >
            <v-icon v-if="isAdminGroup" small @click="doDelete(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            現在登録された管理ユーザーはありません。
          </template>
        </v-data-table>
      </v-card>
      <group-editor :dialog="dialog" :id="editId" @close="close"></group-editor>
      <group-setting-editor
        :dialog="settingDialog"
        :id="settingEditId"
        @close="close"
      ></group-setting-editor>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getGroups, deleteGroup, getSettings } from '@/api'
import GroupEditor from '@/components/group-editor'
import GroupSettingEditor from '@/components/group-setting-editor'
import { MAIN_COLOR } from '@/constants'

export default {
  name: 'GroupIndex',
  components: {
    GroupEditor,
    GroupSettingEditor
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      loading: false,
      search: '',
      dialog: false,
      settingDialog: false,
      editId: '',
      settingEditId: '',
      headers: [
        { text: 'グループ名', value: 'name', sortable: true },
        {
          text: 'LINE公式アカウント名',
          value: 'line_official_account_name',
          sortable: true
        },
        {
          text: '有効 / 無効',
          value: 'enabled',
          align: 'center',
          sortable: true
        },
        { text: '設定', value: 'setting', align: 'center', sortable: false },
        {
          text: '編集 / 削除',
          value: 'action',
          align: 'center',
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['groups', 'isAdminGroup'])
  },
  created() {
    this.doLoad(true)
  },
  methods: {
    ...mapActions([
      'setGroups',
      'deleteGroup',
      'setSettings',
      'setFullscreenLoading'
    ]),
    async doLoad(storeCheck) {
      if (this.loading) {
        return
      }
      this.loading = true
      await this.initGroup(storeCheck)
      await this.initSetting(storeCheck)
      this.loading = false
    },
    async initGroup(storeCheck) {
      if (storeCheck && this.$store.getters.groups.length > 0) {
        return
      }
      let res
      try {
        res = await getGroups()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setGroups(res.groups)
    },
    async initSetting(storeCheck) {
      if (storeCheck && Object.keys(this.$store.getters.settings).length > 0) {
        return
      }
      let res
      try {
        res = await getSettings()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setSettings(res.settings)
    },
    doCreate() {
      this.editId = ''
      this.dialog = true
    },
    doEdit(id) {
      this.editId = id
      this.dialog = true
    },
    doSettingEdit(id) {
      this.settingEditId = id
      this.settingDialog = true
    },
    async doDelete(group) {
      if (!group.is_deletable) {
        alert(
          `グループ名: ${group.name} に紐づくセンサが存在するため削除できません。`
        )
        return
      }
      if (!confirm(`グループ名: ${group.name} を削除してもよろしいですか？`)) {
        return
      }

      this.setFullscreenLoading(true)
      try {
        await deleteGroup(group)
        this.deleteGroup(group)
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.setFullscreenLoading(false)
    },
    close() {
      this.editId = ''
      this.dialog = false
      this.settingEditId = ''
      this.settingDialog = false
    }
  }
}
</script>
